import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const DeepPsychologiePage = () => (
  <Layout>
    <Seo
      title="Tiefenpsychologische Beratung"
      description="Die tiefenpsychologisch orientierte Beratung bezieht unbewusste Motive und die eigene Lebensgeschichte mit ein"
    />
    <section className="flex flex-column flex-row-ns">
      <div className="w-100 w-50-ns">
        <StaticImage
          src={'../../images/eisberg.jpg'}
          alt="Eisberg"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="w-100 w-50-ns mt3 mt0-ns ml3-ns">
        <h1 id="tiefenpsychologie">Tiefenpsychologische Beratung</h1>
        <p>
          Gegenwärtige Konzepte für tiefenpsychologische Beratung und Therapie
          haben ihr gemeinsames Fundament in den bahnbrechenden
          Forschungsresultaten Sigmund Freuds (1856–1939). Freud erkannte, dass
          unterhalb unserer bewussten Wahrnehmung in den Tiefenschichten der
          Psyche unbewusste Prozesse am Werk sind, die das bewusste Seelenleben
          stark beeinflussen. Diese Entdeckung brachte er auf die das
          menschliche Selbstverständnis kränkende Formel, „dass das Ich nicht
          Herr sei in seinem eigenen Haus.“
        </p>
        <p>
          Tiefenpsychologisches Verstehen geht grundsätzlich davon aus, dass
          gegenwärtiges Erleben und Verhalten im Zusammenhang der eigenen
          Lebensgeschichte Sinn macht. Unser Handeln beruht auf Gefühlen und
          Gründen, die sich durch Reflexion identifizieren und bewerten lassen
          und bestimmte Verhaltensweisen nachvollziehbar machen. Allerdings
          reichen offen zu Tage liegende Gründe oftmals nicht aus, um
          dysfunktionales, widersprüchliches und selbstschädigendes Verhalten
          hinreichend zu erklären.
        </p>
        <p>
          Die Tiefenpsychologie weiß um unbewusste Motive, die der eigenen
          Lebensgeschichte entstammen und die sich als sich wiederholende
          Reaktionsmuster in Konfliktsituationen niederschlagen und
          befriedigendere Lösungen verhindern. In einem tiefenpsychologischen
          Beratungsprozess kann das Wahrnehmungsfeld des Beratenen durch das
          Erhellen unbewusster Beweggründe erweitert werden, wodurch neue
          Handlungsspielräume entstehen.
        </p>
      </div>
    </section>
  </Layout>
)

export default DeepPsychologiePage
